const ArchivedDataUploadApiUrls = {
  ACE_HIGHWAY: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/data-restore/upload`,
  ACE_OCEAN: ` ${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/data-restore/upload`,
  SUBMIT: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/data-restore/submit`,
  GET_TPR_USER: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/get`,
  GET_TPR_USER_PREFERENCE: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-preference/get`,
  GET_TPR_USER_DETAILS: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`
};
  
  export default ArchivedDataUploadApiUrls
  