const ReTriggerSubmissionApiUrls = {
  ACI_HIGHWAY_SEARCH_MANIFEST: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/re-trigger-submission/searchManifest`,
  ACI_HIGHWAY_SEARCH_CARGO: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/re-trigger-submission/searchCargo`,
  ACI_HIGHWAY_SEARCH_ARRIVAL_CERT: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/re-trigger-submission/searchArrivalCert`,
  ACI_HIGHWAY_SUBMIT_ALL: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/re-trigger-submission/submitAll`,
  ACI_HIGHWAY_SUBMIT: ` ${process.env.REACT_APP_USER_ACIHW_URL}/aci-highway/re-trigger-submission/submit`,

  ACE_HIGHWAY_SEARCH_MANIFEST: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/re-trigger-submission/searchManifest`,
  ACE_HIGHWAY_SEARCH_SHIPMENT: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/re-trigger-submission/searchShipment`,
  ACE_HIGHWAY_SEARCH_INBOND: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/re-trigger-submission/searchInbond`,
  ACE_HIGHWAY_SEARCH_INBOND_7512: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/re-trigger-submission/search7512`,
  ACE_HIGHWAY_SUBMIT_ALL: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/re-trigger-submission/submitAll`,
  ACE_HIGHWAY_SUBMIT: ` ${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}/ace-highway/re-trigger-submission/submit`,

  EHBL_SEARCH_HOUSE_BILL: ` ${process.env.REACT_APP_USER_EHBL_URL}/ehbl/re-trigger-submission/searchHouseBill`,
  EHBL_SEARCH_CLOSE_MSG: ` ${process.env.REACT_APP_USER_EHBL_URL}/ehbl/re-trigger-submission/searchCloseMessage`,
  EHBL_SUBMIT_ALL: ` ${process.env.REACT_APP_USER_EHBL_URL}/ehbl/re-trigger-submission/submitAll`,
  EHBL_SUBMIT: ` ${process.env.REACT_APP_USER_EHBL_URL}/ehbl/re-trigger-submission/submit`,

  ACI_OCEAN_SEARCH_A6: ` ${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/re-trigger-submission/searchA6`,
  ACI_OCEAN_SEARCH_A6A: ` ${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/re-trigger-submission/searchA6A`,
  ACI_OCEAN_SEARCH_ARRIVAL_MESSAGE: `${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/re-trigger-submission/searchAM`,
  ACI_OCEAN_SUBMIT_ALL: ` ${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/re-trigger-submission/submitAll`,
  ACI_OCEAN_SUBMIT: ` ${process.env.REACT_APP_USER_ACI_OCEAN_URL}/aci-ocean/re-trigger-submission/submit`,
};

export default ReTriggerSubmissionApiUrls